export const WEBSITE_DOMAIN = window.location.origin + "/";
export const WEBSITE_DOMAIN_FOX = "https://www.events.fox";
// export const WEBSITE_DOMAIN_FOX = "http://localhost:3000";
function uppercaseWord(word) {
  return word.toUpperCase();
}



export const WEBSITE_PATH = window.location.hostname;

export const CAPITALIZE_WEBSITE_PATH = uppercaseWord(window.location.hostname);