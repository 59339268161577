import React from "react";
import { useSelector } from "react-redux";
import { TextField } from "@mui/material";

const MuiTextField = (props) => {
  const theme = useSelector((state) => state.theme.themeData);

  return (
    <TextField
      id="outlined-basic"
      label=""
      variant="outlined"
      sx={{
        "& .MuiInputLabel-root": {
          fontSize: props.font === "medium" ? "16px" : "14px",
          height: "40px",
          marginTop: "-5px",
        },
        "& .MuiOutlinedInput-root": {
          fontSize: props.font === "medium" ? "16px" : "14px",
          height: "40px",
          marginTop: "1px",
        },
        "& .MuiOutlinedInput-input": {
          fontFamily: "URW DIN REGULAR",
          fontSize: props.font === "medium" ? "16px" : "14px",
          color: theme?.profile?.primaryColor,
          paddingLeft: "12px", // Apply paddingLeft directly to the input
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          border: props.nameExists ? "1px solid" : "2px solid",
          borderColor: props.nameExists
            ? "#ae0000"
            : theme?.login?.secondaryColor,
          borderRadius: props.borderleft === "none" ? "0px 4px 4px 0px" : "4px",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: props.nameExists
            ? "#ae0000"
            : theme?.login?.secondaryColor,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            borderColor: props.nameExists
              ? "#ae0000"
              : theme?.login?.secondaryColor,
          },
        "& .MuiInputLabel-outlined": {
          paddingLeft: "12px", // Adjust the paddingLeft for the label
        },
      }}
      placeholder={props.placeholder}
      InputLabelProps={{
        style: { color: "#5D7C90", paddingLeft: "12px" }, // Ensure paddingLeft is applied to the label
      }}
      name="text"
      {...props}
    />
  );
};

export default MuiTextField;
