import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { extractValueFromURL } from "../utilities/common";
import { useNavigate } from "react-router-dom";
import { AxiosLocal } from "../utilities/axiosUtils";
import { WEBSITE_DOMAIN_FOX } from "../utilities/websiteUrls.ts";
const AuthenticationPage = () => {
  const meetingUrl = window.location.href;
  const subSpaceSlug = extractValueFromURL(meetingUrl, 5);
  const navigate = useNavigate();
  const urlObject = new URL(window.location.href);

  let path = urlObject.pathname;
  if (path.endsWith("/") && path.length > 1) {
    path = path.slice(0, -1);
  }
  useEffect(() => {
    if (window.localStorage.getObject("accessToken")) {
      AxiosLocal.post(`create/redirect/token`, {
        subspace_slug: subSpaceSlug,
      }).then((response) => {
        if (response.data.status === "Success") {
          console.log("Redirect Token Created", response);
          meetingUrl.includes("lobby")
            ? window.location.replace(
                `${WEBSITE_DOMAIN_FOX}/eventsfox/lobby/${subSpaceSlug}?t=${response.data.redirect_token}`
              )
            : window.location.replace(
                `${WEBSITE_DOMAIN_FOX}/eventsfox/${subSpaceSlug}/${subSpaceSlug}?t=${response.data.redirect_token}`
              );
        }
      });
    } else {
      window.location.replace(`${WEBSITE_DOMAIN_FOX}${path}?t=guest`);
    }
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        backgroundColor: "#011934",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        flexDirection: window.innerWidth <= 400 ? "column" : "row"
      }}
    >
      <div
        style={{
          color: "#88A1AB",
          fontSize:window.innerWidth <= 400 ? 20 : 32 ,
          marginRight: 6,
        }}
      >
        Please wait redirecting to meeting
      </div>
      <div id="loading-container" style={{ marginTop: 32 }}>
        <div id="loading-dots">
          <div className="loading-dot"></div>
          <div className="loading-dot"></div>
          <div className="loading-dot"></div>
        </div>
      </div>
    </div>
  );
};

export default AuthenticationPage;
